<template>
  <main role="main" class="inner cover cover-container">
    <div class="force_center_align">
      <div class="spacer"></div>
      <h1 class="cover-heading usefont">
        Conception, installation, intégration.
      </h1>
      <p class="lead">
        Faites confiance à notre équipe et nos plus de 20 ans d'expérience dans
        le milieu pour vos travaux de menuiserie Aluminium, Acier et PVC ainsi
        que pour la mise en place d'élements domotiques.
      </p>

      <div v-if="this.$parent.status === 'accept'" class="embed-responsive-yt embed-responsive-4by3">
        <iframe class="embed-responsive-item" :src="yt_source"
          title="Vidéo de présentation de l'entreprise LEFLOCH Métal Concept" frameborder="0" allow="encrypted-media"
          allowfullscreen></iframe>
      </div>
      <div v-else >
        <img class="alternative-img-yt" src="/images/ConsentYT.png" alt="Apercu video floue">
      </div>
      <div class="spacer"></div>
      <p class="lead">
        <router-link to="/about" class="btn btn-lg btn-secondary">En savoir plus !
        </router-link>
      </p>
    </div>
    <div class="force_bottom_align">
      <h1 class="cover-heading usefont">Nos partenaires...</h1>
      <div class="container">
        <div class="flex-row">
          <div class="flex-flat">
            <img class="image_part img-responsive" src="/images/Logo_PARTENAIRE_PROFERM.png" alt="ProfermPart" />
          </div>
          <div class="flex-flat">
            <img class="image_part img-responsive" src="/images/Logo_PARTENAIRE_CAME.png" alt="CAMEPart" />
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
export default {
  name: "Home",
  metaInfo: {
    title: "Accueil",
    titleTemplate: "%s | LEFLOCH Métal Concept",
  },
  props: {
    yt_source: {
      type: String,
      default: "https://www.youtube-nocookie.com/embed/xlL9WqR3JL4",
    }
  }
};

</script>

<style lang="css">
.image_part {
  max-width: 45%;
}

@media screen and (max-width: 600px) {
  .spacer {
    height: 0;
  }
}

.flex-row {
  width: 70%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

.flex-flat {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .flex-row {
    margin-bottom: 15%;
  }
}

.flex-row>div {
  flex: 1;
}

.flex-row>div>img {
  width: 100%;
  min-width: 250px;
}

.force_center_align {
  justify-content: center;
}

@media (min-width: 576px) {
  .embed-ex {
    padding: 1.5rem;
    margin-right: 0;
    margin-left: 0;
    border-width: 0.2rem;
  }
}

@media (max-width: 576px) {
  .embed-responsive-yt {
    width: 100% !important;
  }
}

.embed-ex {
  padding: 1.5rem;
  margin-right: 0;
  margin-left: 0;
  border-width: 0.2rem;
}

.alternative-img-yt {
    width: 100%;
    height: auto;
    margin-bottom: 2%;
    max-height: 400px;
    overflow: hidden;
    border-radius: 10px;
  }
</style>

<style lang="scss">
.embed-responsive-yt {
  position: relative;
  display: block;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1%;
  max-height: 400px;
  overflow: hidden;

  &::before {
    display: block;
    content: "";
  }

  .embed-responsive-item,
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.embed-responsive-4by3 {
  &::before {
    padding-top: 100%;
  }
}
</style>
